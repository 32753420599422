








import { Component, Vue } from 'vue-property-decorator';
import PaymentForm from '@/components/PaymentForm.vue';

@Component({
  name: 'Pay'
})
export default class Pay extends Vue {}
